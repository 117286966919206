import { Radio } from "antd";
import { Guid } from "guid-typescript";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

declare const window: {
  kuika: {
    isDesignTime: boolean;
  };
};

interface IKRadioButtonProps {
  selectedButtonColor: string;
  selectedButtonBgColor: string;
  splitterColor: string;
  label: string;
  value: string;
  style: any;
}

interface IKRadioButtonState {
  uniqueKey: Guid;
}

class KRadioButton extends PureComponent<IKRadioButtonProps & CommonProps, IKRadioButtonState> {
  private memoizedDynamicCssResult = "";

  constructor(props: IKRadioButtonProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount() {
    this.setDynamicStyle();
  }

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const { paddingTop, paddingBottom, color, fontSize, fontWeight, fontFamily, width, height } = this.props.style;
    if (paddingTop === 8 && paddingBottom === 8) {
      result += `.${className.trim()} {
          padding: 4px 8px !important;
        }`;
    }
    if (color) {
      result += `.${className.trim()} {
          color: ${color} !important;
        }`;
    }
    if (fontSize) {
      result += `.${className.trim()} {
          font-size: ${fontSize} !important;
        }`;
    }
    if (fontWeight) {
      result += `.${className.trim()} {
          font-weight: ${fontWeight} !important;
        }`;
    }
    if (fontFamily) {
      result += `.${className.trim()} {
          font-family: ${fontFamily} !important;
        }`;
    }
    if (width) {
      result += `.${className.trim()} {
          width: ${width} !important;
          display: inline-block !important;
        }`;
    }
    if (height) {
      result += `.${className.trim()} {
          height: ${height} !important;
          display: inline-block !important;
        }`;
    }
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kradiobutton_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getLabel = (label: string): string => {
    if (!window.kuika?.isDesignTime) return label;
    if (!label.includes(":") || label.length < 10) return label;

    return `${label.substring(0, 10)}...`;
  };

  render(): ReactNode {
    return (
      <Radio.Button
        className={this.getClassName()}
        value={this.props.value}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        {this.getLabel(this.props.label)}
      </Radio.Button>
    );
  }
}

const kRadioButton = withCommonEvents(KRadioButton);
export { kRadioButton as KRadioButton };
