import { Radio, Space } from "antd";
import { RadioGroupProps } from "antd/lib/radio";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../../../components/web-components/common/common-props";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface IKRadioGroupProps extends RadioGroupProps {
  space?: number;
  selectedFontColor?: string;
  selectedBgColor?: string;
  unselectedBgColor?: string;
}

interface IKRadioGroupState {
  uniqueKey: Guid;
}

declare let window: Window & { kuika: any };

class KRadioGroup extends PureComponent<IKRadioGroupProps & CommonProps, IKRadioGroupState> {
  private memoizedDynamicCssResult = "";

  constructor(props: IKRadioGroupProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount = () => {
    this.setDynamicStyle();
  };

  componentDidUpdate = () => {
    this.setDynamicStyle();
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }

    let result = "";

    if (
      this.props.style?.color ||
      this.props.style?.fontSize ||
      this.props.style?.fontFamily ||
      this.props.style?.fontWeight ||
      this.props.unselectedBgColor
    ) {
      const color = `color: ${this.props.style?.color};`;
      const fontSize = `font-size: ${this.props.style?.fontSize};`;
      const fontFamily = `font-family: ${this.props.style?.fontFamily};`;
      const fontWeight = `font-weight: ${this.props.style?.fontWeight};`;
      const bgColor = `background-color: ${this.props.unselectedBgColor};`;

      result += `
      .${this.getClassName().trim()} .ant-radio-button-wrapper {
        ${this.props.style?.color && color}
        ${this.props.style?.fontSize && fontSize}
        ${this.props.unselectedBgColor && bgColor}
        ${this.props.style?.fontFamily && fontFamily}
        ${this.props.style?.fontWeight && fontWeight}
      }
      `;
    }

    if (this.props.style?.display === "inline") {
      result += `
          .${this.getClassName().trim()} {
            display: inline-block !important;
          }
          `;
    }

    if (this.props.selectedBgColor || this.props.selectedFontColor || this.props.style?.color) {
      const selectedBgColor = this.props.selectedBgColor
        ? `
        background-color: ${this.props.selectedBgColor} !important;`
        : "";

      const selectedFontColor = this.props.selectedFontColor
        ? `
        color: ${this.props.selectedFontColor} !important;`
        : "";

      result += `
        .${this.getClassName().trim()} .ant-radio-button-wrapper-checked {
          ${selectedBgColor}
          ${selectedFontColor}
        }
      `;
    }

    return result;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kradiogroup_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getProps = () => {
    const props: any = _.clone(this.props);

    if (props.options) {
      delete props.options;
    }
    if (props.className) {
      delete props.className;
    }

    if (props.children) delete props.children;

    if (props.style) {
      const style: any = _.clone(this.props.style);
      if (style.fontSize) delete style.fontSize;
      if (style.color) delete style.color;
      if (style.fontFamily) delete style.fontFamily;
      if (style.fontWeight) delete style.fontWeight;

      props.style = style;
    }

    props.buttonStyle = "solid";
    props.className = this.getClassName();

    return props;
  };

  render(): ReactNode {
    return (
      <>
        <Radio.Group {...this.getProps()}>
          {this.props.space ? (
            <Space size={this.props.space} wrap>
              {this.props.children}
            </Space>
          ) : (
            <>{this.props.children}</>
          )}
        </Radio.Group>
      </>
    );
  }
}

const kRadioGroup = withCommonEvents(KRadioGroup);
export { kRadioGroup as KRadioGroup };
